<template>
  <SplitPage v-if="authorization && previousStep">
    <template #header>
      <AuthorizationBreadcrumb :active-step="2"/>
    </template>

    <template #left-top>
      <StepsTabs/>
    </template>

    <template #left-content>
      <StepBlock @delete-step-from-database="deleteStepFromDatabase"
                 :is-previous-step="true"
                 :authorization="authorization"
                 :step="previousStep"
                 :map="map"/>
      <template v-if="!lastStepAdded">
        <LocationAutocomplete @input="addStepToStore"
                              :authorization="authorization"
                              :way="previousStep.properties.way"
                              heading-text="Renseignez le point d'intersection suivant"/>
        <StepCreateButton @input="addStepToStore"
                          :authorization="authorization"
                          :way="previousStep.properties.way"
                          :map-center="mapCenter"
                          class="mb-3"/>
        <StepCreateButton v-if="showReturnButton"
                          :is-return-button="true"
                          @input="addStepToStore"
                          :authorization="authorization"
                          :way="1"
                          :map-center="mapCenter"
                          class="mb-3"/>
        <button v-if="showRecapButton"
                @click="goToStepsIndex"
                class="btn btn-block btn-outline-secondary">
          Récapitulatif
        </button>
        <button v-if="showVisualizeButton"
                @click="visualize"
                class="btn btn-block btn-outline-secondary">
          Visualiser L'itinéraire
        </button>
      </template>
      <template v-else>
        <StepBlock @remove-step-from-store="removeStepFromStore"
                   :is-previous-step="false"
                   :authorization="authorization"
                   :step="lastStepAdded"
                   :map="map"/>
        <StepDetails :step="lastStepAdded"/>
        <button @click="save(lastStepAdded)"
                class="btn btn-block btn-secondary">
          Valider
        </button>
      </template>
    </template>

    <template #right-content>
      <AuthorizationMap @map-ready="onMapReady"
                        @map-update-center="onMapUpdateCenter"
                        @map-update-bounds="onMapUpdateBounds"
                        :view-step="previousStep"
                        :center="center"/>
    </template>
  </SplitPage>
</template>

<script>
import SplitPage from '@/components/SplitPage'
import AuthorizationBreadcrumb from '@/components/AuthorizationBreadcrumb'
import StepsTabs from '@/components/StepsTabs'
import StepBlock from '@/components/StepBlock'
import LocationAutocomplete from '@/components/LocationAutocomplete'
import StepCreateButton from '@/components/StepCreateButton'
import StepDetails from '@/components/StepDetails'
import AuthorizationMap from '@/components/AuthorizationMap'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AuthorizationsStepsCreateNextPage',
  components: {
    SplitPage,
    AuthorizationBreadcrumb,
    StepsTabs,
    StepBlock,
    LocationAutocomplete,
    StepCreateButton,
    StepDetails,
    AuthorizationMap
  },
  data () {
    return {
      map: null,
      mapCenter: null,
      mapBounds: null
    }
  },
  computed: {
    ...mapState({
      authorization: state => state.authorization.authorization,
      steps: state => state.authorization.steps,
      lastStepAdded: state => state.authorization.lastStepAdded
    }),
    previousStep () {
      return this.steps[this.$route.params.num_step - 1]
    },
    center () {
      return [this.previousStep.properties.latitude, this.previousStep.properties.longitude]
    },
    showReturnButton () {
      if (this.steps.find(step => step.properties.way === 1)) {
        // we do not show the return button if the store has a step with way 1
        return false
      }
      if (this.previousStep.properties.way === 1) {
        return false
      }
      if (this.steps.length < 2) {
        return false
      }
      return true
    },
    showRecapButton () {
      return this.showVisualizeButton
    },
    showVisualizeButton () {
      return this.steps.length >= 2
    }
  },
  methods: {
    ...mapActions({
      getAuthorization: 'authorization/getAuthorization',
      getSteps: 'authorization/getSteps',
      addStep: 'authorization/addStep',
      removeStep: 'authorization/removeStep',
      saveSteps: 'authorization/saveSteps',
      resetState: 'authorization/resetState'
    }),
    onMapReady (map) {
      this.map = map
    },
    onMapUpdateCenter (center) {
      this.mapCenter = center
    },
    onMapUpdateBounds (bounds) {
      this.mapBounds = bounds
    },
    addStepToStore (step) {
      this.addStep({
        step,
        index: parseInt(this.$route.params.num_step)
      })
      this.map.panTo([step.properties.latitude, step.properties.longitude])
    },
    save (step) {
      this.saveSteps().then(() => {
        this.$router.push({
          name: 'authorizations.steps.create-next',
          params: {
            id: this.$route.params.id,
            num_step: step.properties.num_step
          }
        })
      })
    },
    goToStepsIndex () {
      this.$router.push({
        name: 'authorizations.steps.index',
        params: {
          id: this.$route.params.id
        }
      })
    },
    visualize () {
      this.$router.push({
        name: 'authorizations.steps.show',
        params: {
          id: this.$route.params.id,
          num_step: 1
        }
      })
    },
    deleteStepFromDatabase (previousStep) {
      if (window.confirm('Êtes-vous sûr de vouloir supprimer ce point ? Cette action est irreversible.')) {
        this.removeStep(previousStep).then(() => {
          this.saveSteps().then(() => {
            if (previousStep.properties.num_step === 1) {
              // we just deleted the only one step for this authorization, we
              // redirect the user to the page where he would be able to create
              // the first step
              this.$router.push({
                name: 'authorizations.steps.create',
                params: {
                  id: this.$route.params.id
                }
              })
            } else {
              // after deleting the previous step from the database, we
              // redirect the user to the correct create-next page
              this.$router.push({
                name: 'authorizations.steps.create-next',
                params: {
                  id: this.$route.params.id,
                  num_step: previousStep.properties.num_step - 1
                }
              })
            }
          })
        })
      }
    },
    removeStepFromStore (step) {
      this.removeStep(step).then(() => {
        // reload current route
        window.location.reload(true)
      })
    }
  },
  mounted () {
    if (this.$route.params.num_step === undefined
      || this.$route.params.num_step === null) {
      this.$router.push({ name: '404' })
    }
    this
      .getAuthorization(this.$route.params.id)
      .then(() => this.getSteps(this.$route.params.id))
      .catch(() => this.$router.push({ name: '404' }))
  },
  beforeDestroy () {
    this.resetState()
  }
}
</script>

<style scoped lang="scss">

</style>
